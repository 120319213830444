import React, {Component} from 'react';

const SERVICE_URL = 'https://weather.strelkov.net/sensor-data';

const initialData = {
    temperature: 0,
    humidity: 0,
    voltage: 0,
    created_at: '2000-01-01 00:00:00'
};

const formatTime = (date) => date.toLocaleTimeString("ru-RU", {hour: 'numeric', minute: 'numeric'});
const formatDate = (date) => date.toLocaleDateString("ru-RU", {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'});
const formatDateTime = (date) => (date.toLocaleDateString("ru-RU", {day: 'numeric', month: 'long'}) + ', ' + formatTime(date));

const prepare = (item) => {
    return {
        temperature: parseFloat(item.temperature),
        humidity: parseFloat(item.humidity),
        voltage: parseFloat(item.voltage),
        dat: new Date(Date.parse(item.created_at)),
    }
};

class Current extends Component {

    constructor(props) {
        super(props);
        this.state = {
            time: formatTime(new Date()),
            date: formatDate(new Date()),
            inside: prepare(initialData),
            outside:  prepare(initialData)
        };
    }

    fetchData() {
        fetch(SERVICE_URL)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({outside: prepare(result.data.outdoor), inside: prepare(result.data.indoor)});
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    componentDidMount() {
        this.fetchData();
        setInterval(() => {
            this.setState({
                time: formatTime(new Date()),
                date: formatDate(new Date()),
            })
        }, 60000);
    }
    render() {
        return (
            <div className="current">
                <div>&nbsp;</div>
                <div className="time">{this.state.time}</div>
                <div className="full-date">{this.state.date.split(',')[1]}</div>
                <div className="weekday">{this.state.date.split(',')[0]}</div>
                <div>&nbsp;</div>

                <div className="row">
                    <div className="column outside">
                        <center>
                            <img src="/img/icons/02d.svg" alt="Clouds" loading="lazy" width="40%"/>
                        </center>
                        <div className="temp">{this.state.outside.temperature}&deg;</div>
                        <div className="humidity">{this.state.outside.humidity}%</div>
                        <div  className={this.state.outside.voltage > 3 ? 'voltage' : 'voltage low'}>{parseFloat(this.state.outside.voltage).toFixed(2)}</div>
                        <div className="dat">{formatDateTime(this.state.outside.dat)}</div>
                    </div>
                    <div className="column inside">
                        <center>
                            <img src="/img/home.svg" alt="Clouds" loading="lazy" width="40%"/>
                        </center>
                        <div className="temp">{this.state.inside.temperature}&deg;</div>
                        <div className="humidity">{this.state.inside.humidity}%</div>
                        <div className={this.state.outside.voltage > 3 ? 'voltage' : 'voltage low'}>{parseFloat(this.state.inside.voltage).toFixed(2)}</div>
                        <div className="dat">{formatDateTime(this.state.inside.dat)}</div>
                    </div>
                </div>

            </div>
        )
    }
}


export default Current;