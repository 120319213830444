import React from 'react';
import './App.scss';

import Forecast from './components/Forecast'
import Current from './components/Current'
import RadioList from './components/RadioList'

function App() {
    return (
        <div className="app">
            <Current></Current>
            <Forecast></Forecast>
            <p>&nbsp;</p>
            <RadioList></RadioList>
        </div>
    );
}
export default App;