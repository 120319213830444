import React, { Component } from 'react';

export default class RadioList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [
                {
                    id: 'yes',
                    name: 'Yes FM Russia',
                    src: 'https://myradio24.org/alexx777',
                    img: '/img/radio/yes.svg'
                },
                {
                    id: 'radio-7',
                    name: 'Радио 7',
                    src: 'https://emgregion.hostingradio.ru:8064/moscow.radio7.mp3',
                    img: '/img/radio/radio-7.svg'
                },
                {
                    id: 'nrj',
                    name: 'Радио Energy',
                    src: 'https://ic7.101.ru:8000/v1_1',
                    img: '/img/radio/nrj.svg'
                },
                {
                    id: 'monte-carlo',
                    name: 'Monte Carlo',
                    src: 'https://dfm.hostingradio.ru/montecarlo128.mp3',
                    img: '/img/radio/monte-carlo.svg'
                },
                {
                    id: 'mega',
                    name: 'La Mega Malaga',
                    src: 'https://streaming2.elitecomunicacion.es:8220/stream',
                    img: '/img/radio/mega.svg'
                },
            ],
            text: '',
            active: null
        };
        this.playerRef = React.createRef();
        this.onClick = this.onClick.bind(this);
    }

    get player () {
        return this.playerRef.current;
    }

    onClick (e) {
        const index = e.currentTarget.dataset.index;
        if (index in this.state.list) {
            const item = this.state.list[index];

            if (this.state.active) {
                this.player.pause();
                this.player.currentTime = 0;
            }

            if (this.state.active !== item.id) {
                this.player.src = item.src;
                this.player.play();
                this.setState({ active: item.id });
            } else {
                this.setState({ active: null });
            }
        }
    }

    render() {
        return (
            <div className="radio-list">
                {this.state.list.map((item, index) => {
                    return <div key={item.id}
                                onClick={this.onClick}
                                data-index={index}
                                className={this.state.active === item.id ? 'active' : ''}>
                        <img src={item.img} alt={item.name} loading="lazy"/>
                    </div>
                })}
                <audio id="audio-player" type="audio/mpeg" ref={this.playerRef}/>
            </div>
        )
    }
}