import React, {Component} from 'react';

//const SERVICE_URL = 'https://api.openweathermap.org/data/2.5/forecast?lon=-4.430704&lat=36.713490&appid=12d4ce5a91d2ef4cdf68f69598417aeb';
const SERVICE_URL = 'https://weather.strelkov.net/weather/forecast';

class Forecast extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            isFetching: false
        }
    }

    fetchData() {
        setTimeout(() => {
            fetch(SERVICE_URL)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({list: result.data})
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
        }, 500);
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <div className="forecast">
                {this.state.list.map(item => {
                    return <div key={item.dt}>
                        <img src={'/img/icons/' + item.weather[0].icon + '.svg'} alt={item.weather[0].main} loading="lazy"/>
                        <div className="temp">
                            {Math.round(item.main.temp)}&deg;
                        </div>
                        <div className="date">
                            {item.dt_txt.substr(11, 5)}
                        </div>
                    </div>
                })}
            </div>
        )
    }
}

export default Forecast;